
import { Component, Vue, Prop } from 'vue-property-decorator';
import { PRICE_SHOWN } from '@/modules/rates/constants';
import Currency from '@/modules/common/components/currency.vue';
import ScreenshotLink from '@/modules/rates/components/screenshot-link.vue';
import PriceFilter from '@/modules/common/filters/price.filter';
import CompsetModel from '@/modules/compsets/models/compset.model';
import LosIndicator from '@/modules/common/components/los-indicator.vue';
import Intraday from '@/modules/common/components/intraday.vue';
import { calculateDiff } from '@/modules/common/utils/calculate-diff';
import PRICE from '@/modules/common/modules/rates/constants/price.enum';
import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';
import type RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';

import { ColumnKey } from '../../constants';
import type {
    TableColumns, TableOptions, TrendsDocument, TrendsStatistics,
} from '../../types';

const BOOKING_BASIC_ICON = require('@/modules/common/assets/booking-basic.svg');

interface TableRow {
    entityId: string;
    [ColumnKey.SCREENSHOT]?: string;
    [ColumnKey.PRICE_TYPE]?: string;
    [ColumnKey.COLOR]?: string;
    [ColumnKey.NAME]?: string;
    [ColumnKey.RANK]?: string | null;
    [ColumnKey.PRICE]?: number | null;
    // Price compare 1/2 not implemented yet
    [ColumnKey.PRICE_COMPARE_1]?: number | null;
    [ColumnKey.PRICE_COMPARE_2]?: number | null;
    [ColumnKey.ROOM_NAME]?: string | null;
    [ColumnKey.BASIC]?: boolean;
    [ColumnKey.INTRADAY]?: number | null;
    [ColumnKey.PROVIDERS]?: string[] | null;

    isMyHotel?: boolean;
    isCompset?: boolean;
    // Providers not implemented yet
    providers?: string[];
    link?: string;
    isNetCalc?: boolean;
    isTotalCalc?: boolean;
    losRestriction?: boolean | number | null;
    pax?: number[];
    isSpecialIntraday?: boolean;
    price1ColumnRoomName?: string | null;
    price2ColumnRoomName?: string | null,
}

@Component({
    components: {
        Currency, ScreenshotLink, LosIndicator, Intraday, ProviderCard,
    },
})
export default class Table extends Vue {
    @Prop({ type: Object })
    trendsDocument!: TrendsDocument;

    @Prop({ type: Number })
    trendIndex!: number;

    @Prop({ type: Array })
    entities!: string[];

    @Prop({ type: Object })
    columns!: TableColumns;

    @Prop({ type: String })
    priceShown!: PRICE_SHOWN;

    @Prop({ type: Object })
    compset!: CompsetModel;

    @Prop({ type: Object, default: () => ({}) })
    hiddenEntities!: Record<string, boolean>;

    @Prop({ type: Object })
    entityColors!: Record<string, string>;

    @Prop({ type: Object })
    options!: TableOptions;

    @Prop({ type: Array })
    statistics!: (TrendsStatistics | null)[];

    readonly ColumnKey = ColumnKey;
    readonly PriceFilter = PriceFilter;
    readonly bookingBasicIcon = BOOKING_BASIC_ICON;

    get availableColumnKeys() {
        return Object.keys(this.columns).filter(key => this.columns[(key as ColumnKey)]);
    }

    get tableData() {
        if (this.isNoValidData) {
            return null;
        }

        if (!this.trendsDocument.trendData[this.trendIndex] || !this.statistics[this.trendIndex]) {
            return null;
        }

        const { data } = this.trendsDocument.trendData[this.trendIndex]!;
        const { names } = this.trendsDocument;

        let numberOfRoomsWithPrices = 0;

        // Format table rows according provided entities for any mode regular/all/cheapest/cluster
        const formatedData = this.entities.map(entityId => {
            const entityData = data[entityId];
            const entityName = names[entityId] || entityId;

            const emptyRow = {
                entityId,
                isMyHotel: entityId === String(this.compset.ownerHotelId),
                [ColumnKey.COLOR]: this.entityColors[entityId],
                [ColumnKey.NAME]: entityName,
            } as TableRow;

            if (!entityData) {
                return emptyRow;
            }

            const [mainRoom, ...restRooms] = entityData.rooms;

            if (!mainRoom?.price) {
                if (!restRooms.length) {
                    return emptyRow;
                }

                return {
                    ...emptyRow,
                    [`${ColumnKey.PRICE_COMPARE_1}RoomName`]: this.options.isDiffRoomNamesIconShown ? restRooms[0]?.roomName : null,
                    [`${ColumnKey.PRICE_COMPARE_2}RoomName`]: this.options.isDiffRoomNamesIconShown ? restRooms[1]?.roomName : null,
                    [ColumnKey.PRICE_COMPARE_1]: restRooms[0]?.price ? restRooms[0].price[`${this.priceShown}Price` as unknown as keyof NonNullable<RatesDocumentItemModel['price']>] : null,
                    [ColumnKey.PRICE_COMPARE_2]: restRooms[1]?.price ? restRooms[1].price[`${this.priceShown}Price` as unknown as keyof NonNullable<RatesDocumentItemModel['price']>] : null,
                };
            }

            const price = mainRoom.price
                ? mainRoom.price[`${this.priceShown}Price` as keyof typeof mainRoom.price]
                : null;

            if (price && price !== PRICE.NA) {
                numberOfRoomsWithPrices += 1;
            }

            const compRate = this.statistics[this.trendIndex]?.compset?.compRates[0];
            let formatedDiff = '';

            if (price && price !== PRICE.NA && compRate) {
                const diff = calculateDiff(price, compRate) as number;
                if (typeof diff === 'number') {
                    formatedDiff = `${diff < 0 ? '' : '+'}${Math.round(diff)}%`;
                }
            }

            return {
                entityId,
                isMyHotel: entityId === String(this.compset.ownerHotelId),
                isCompset: false,
                isNetCalc: this.options.isNetTotalCalcShown && mainRoom.isNetCalc && this.priceShown === PRICE_SHOWN.NET,
                isTotalCalc: this.options.isNetTotalCalcShown && mainRoom.isTotalCalc && this.priceShown === PRICE_SHOWN.TOTAL,
                losRestriction: entityData.losRestriction,
                isSpecialIntraday: restRooms[0] ? restRooms[0].intradayBySpecialDate : false,
                link: (typeof price === 'number' && price !== PRICE.NA) ? entityData.link : null,
                pax: entityData.pax,
                [`${ColumnKey.PRICE_COMPARE_1}RoomName`]: this.options.isDiffRoomNamesIconShown ? restRooms[0]?.roomName : null,
                [`${ColumnKey.PRICE_COMPARE_2}RoomName`]: this.options.isDiffRoomNamesIconShown ? restRooms[1]?.roomName : null,
                [ColumnKey.SCREENSHOT]: entityData.screenshot,
                [ColumnKey.PRICE_TYPE]: (price && price !== PRICE.NA) ? `price.${mainRoom.priceType}` : null,
                [ColumnKey.COLOR]: this.entityColors[entityId],
                [ColumnKey.NAME]: entityName,
                [ColumnKey.RANK]: null,
                [ColumnKey.BASIC]: mainRoom.isBasic,
                [ColumnKey.PRICE]: price,
                [ColumnKey.PRICE_COMPARE_1]: restRooms[0]?.price ? restRooms[0].price[`${this.priceShown}Price` as keyof typeof mainRoom.price] : null,
                [ColumnKey.PRICE_COMPARE_2]: restRooms[1]?.price ? restRooms[1].price[`${this.priceShown}Price` as keyof typeof mainRoom.price] : null,
                [ColumnKey.ROOM_NAME]: (price && price !== PRICE.NA) ? mainRoom.roomName : null,
                [ColumnKey.DIFF]: formatedDiff,
                [ColumnKey.PROVIDERS]: entityData.providers,
            } as TableRow;
        });

        const { compset } = this.statistics[this.trendIndex]!;

        // Adds compset row (median/heigh/low/average) if it is in the statistics
        if (compset && compset.compRates.some(r => !!r)) {
            formatedData.push({
                entityId: compset.type,
                isCompset: true,
                [ColumnKey.NAME]: String(this.$t(`compsetRate.${compset.type}`)),
                [ColumnKey.PRICE]: compset.compRates[0],
                [ColumnKey.PRICE_COMPARE_1]: compset.compRates[1] || null,
                [ColumnKey.PRICE_COMPARE_2]: compset.compRates[2] || null,
                [ColumnKey.ROOM_NAME]: null,
            } as TableRow);
        }

        if (!(ColumnKey.PRICE in this.columns)) {
            return formatedData;
        }

        // Calculate rank and sort by prices if PRICE columns is in columns prop
        const sortedDataWithRank = formatedData
            .sort((a, b) => {
                const p1 = typeof b[ColumnKey.PRICE] !== 'number' ? -1 : b[ColumnKey.PRICE]!;
                const p2 = typeof a[ColumnKey.PRICE] !== 'number' ? -1 : a[ColumnKey.PRICE]!;
                return p1 - p2;
            })
            .map(tableRow => {
                if (!tableRow[ColumnKey.PRICE] || tableRow[ColumnKey.PRICE]! <= 0 || tableRow.isCompset) {
                    return {
                        ...tableRow,
                        [ColumnKey.RANK]: null,
                    };
                }

                const rank = numberOfRoomsWithPrices;
                numberOfRoomsWithPrices -= 1;

                return {
                    ...tableRow,
                    [ColumnKey.RANK]: String(rank),
                };
            });

        if (this.options.maxEntities !== undefined && this.options.maxEntities < sortedDataWithRank.length) {
            const { maxEntities } = this.options;
            let centralRowIndex = sortedDataWithRank.findIndex(item => item.isCompset);

            if (centralRowIndex === -1) {
                centralRowIndex = sortedDataWithRank.findIndex(item => item.isMyHotel);
            }

            if (centralRowIndex === -1) {
                return sortedDataWithRank.slice(0, maxEntities);
            }

            let firstElement = centralRowIndex - Math.floor(maxEntities / 2);
            firstElement = firstElement > 0 ? firstElement : 0;

            return sortedDataWithRank.slice(firstElement, firstElement + maxEntities + 1);
        }

        return sortedDataWithRank;
    }

    get isNoValidData() {
        return !this.trendsDocument?.trendData || !this.trendsDocument.trendData[this.trendIndex]?.data || !this.entities;
    }

    isAnyRoomWithAnyPrice(column: keyof TableRow) {
        if (!this.tableData) {
            return false;
        }

        return this.tableData.some(e => typeof e[column] === 'number');
    }

    formatTextField(input: any) {
        if (!input) {
            return '---';
        }

        if (typeof input !== 'string' || !this.$te(input)) {
            return input;
        }

        return this.$tc(input);
    }

    handleToggleEntity(entityId: string) {
        this.$emit('toggleEntity', entityId);
    }

    getCalculationMessage(entity: TableRow) {
        if (entity.isNetCalc) return this.$tc('rates.netRateMsg');
        if (entity.isTotalCalc) return this.$tc('rates.totalRateMsg');
        return '';
    }
}
