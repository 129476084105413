
import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from 'moment';
import CustomTooltipV2, { Rect, TOOLTIP_DIRECTION } from '@/modules/common/components/ui-kit/custom-tooltip-v2.vue';
import { PRICE_SHOWN } from '@/modules/rates/constants';
import CompsetModel from '@/modules/compsets/models/compset.model';
import Table from './table.vue';
import type {
    TrendsDocument, TableOptions, TrendsStatistics, TableColumns,
} from '../../types';

@Component({
    components: { CustomTooltipV2, Table },
})
export default class Tooltip extends Vue {
    @Prop({ type: HTMLElement, default: null })
    focusElement!: HTMLElement | null;

    @Prop({ type: Object, default: null })
    trendsDocument!: TrendsDocument | null;

    @Prop({ type: Number })
    trendIndex!: number;

    @Prop({ type: Array })
    entities!: string[];

    @Prop({ type: Object })
    columns!: TableColumns;

    @Prop({ type: String })
    priceShown!: PRICE_SHOWN;

    @Prop({ type: Object })
    compset!: CompsetModel;

    @Prop({ type: Object })
    entityColors!: Record<string, string>;

    @Prop({ type: Object })
    options!: TableOptions;

    @Prop({ type: Array })
    statistics!: (TrendsStatistics | null)[];

    readonly direction = TOOLTIP_DIRECTION.RIGHT;
    private _tooltipPosition: Rect | null = null;
    private _prevTrendIndex: number | null = null;

    get parentPos(): Rect {
        return {
            x: 0, y: (this.focusElement?.getBoundingClientRect().y || 0), width: window.innerWidth, height: window.innerHeight,
        };
    }

    get tooltipPosition() {
        if (!this.focusElement && this._tooltipPosition || this.trendIndex === null) {
            this._tooltipPosition = null;
            this._prevTrendIndex = null;
        } else if (this.focusElement && !this._tooltipPosition
            || this.focusElement && this._tooltipPosition && this.trendIndex !== this._prevTrendIndex
        ) {
            const {
                x, y, width, height,
            } = this.focusElement.getBoundingClientRect();

            this._tooltipPosition = {
                x,
                y,
                width,
                height,
            } as Rect;

            this._prevTrendIndex = this.trendIndex;
        }

        return this._tooltipPosition;
    }

    get date() {
        const utcDate = this.trendsDocument?.trendData[this.trendIndex]?.updateDate;

        if (!utcDate) {
            return '--- --, ----';
        }

        return moment(utcDate).format('MMM D, YYYY');
    }
}
