
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ChartData, ChartDataSets, ChartOptions } from 'chart.js';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import CompsetModel from '@/modules/compsets/models/compset.model';
import RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';
import { PRICE_SHOWN } from '@/modules/rates/constants';
import PriceFilter from '@/modules/common/filters/price.filter';
import { hexToRgb } from '@/modules/common/constants/default-graph-colors.constant';
import type HelperService from '@/modules/common/services/helper.service';

import Tooltip from './tooltip.vue';
import { ColumnKey, NUMBER_OF_CHART_DAYS } from '../../constants';
import type { TableOptions, TrendsDocument, TrendsStatistics } from '../../types';

const BOOKING_BASIC_ICON = require('@/modules/common/assets/booking-basic.svg');

@Component({
    components: {
        CustomGraph,
        Tooltip,
    },
})
export default class TrendsChart extends Vue {
    @inject(KEY.HelperService) private helperService!: HelperService;

    @Prop({ type: Object })
    trendsDocument!: TrendsDocument | null;

    @Prop({ type: Array, default: null })
    entities!: string[] | null;

    @Prop({ type: Object })
    columns!: Record<ColumnKey, {
        label: string;
        width: string;
    }>;

    @Prop({ type: String })
    priceShown!: PRICE_SHOWN;

    @Prop({ type: Object })
    compset!: CompsetModel;

    @Prop({ type: Object })
    entityColors!: Record<string, string>;

    @Prop({ type: Object })
    hiddenEntities!: Record<string, boolean>;

    @Prop({ type: Object })
    options!: TableOptions;

    @Prop({ type: Array })
    statistics!: (TrendsStatistics | null)[];

    readonly ColumnKey = ColumnKey;
    readonly PriceFilter = PriceFilter;
    readonly bookingBasicIcon = BOOKING_BASIC_ICON;

    tooltipFocusElement: HTMLElement | null = null;
    tooltipTrendIndex: number | null = null;

    get shownEntities() {
        if (!this.entities) {
            return null;
        }

        if (!this.hiddenEntities) {
            return this.entities;
        }

        return this.entities.filter(e => !this.hiddenEntities[e]);
    }

    get isNoData() {
        if (!this.trendsDocument?.trendData || !this.entities) {
            return true;
        }

        // Try to find item in trendData with actual data.
        return !this.trendsDocument.trendData
            .find(v => (v !== null && Object.keys(v.data).filter(entityKey => this.entities!.find(e => e === entityKey)).length));
    }

    get chartOptions() {
        const currency = this.trendsDocument?.currency || null;

        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                point: {
                    radius: 4,
                    backgroundColor: 'white',
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#9B9B9B',
                    },
                    ticks: {
                        callback: v => {
                            if (v !== '-00') {
                                return v;
                            }

                            const t = this.$i18n.tc('lastUpd').split(' ');

                            // ENG
                            if (t.length === 2) {
                                return t;
                            }

                            // CN or JP
                            return [t[0].slice(0, 2), t[0].slice(2)];
                        },
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#9B9B9B',
                    },
                    ticks: {
                        autoSkip: true,
                        padding: 10,
                        min: 0,
                        callback: value => `${this.helperService.currencySymbol(currency)}${value}`,
                    },
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        } as ChartOptions;
    }

    get chartData(): ChartData {
        if (!this.entities
            || !this.entities.length
            || !this.trendsDocument
            || !this.trendsDocument.trendData
        ) {
            return {
                labels: [this.$tc('noData')],
                datasets: [],
            };
        }

        const labels = Array.from(Array(NUMBER_OF_CHART_DAYS).keys()).reverse().map(trendIndex => (
            String(trendIndex).length === 1
                ? `-0${trendIndex}`
                : `-${trendIndex}`
        ));

        // If not compare mode - 1, otherwise 2 or 3
        const roomsNumber = [
            this.columns[ColumnKey.PRICE],
            this.columns[ColumnKey.PRICE_COMPARE_1],
            this.columns[ColumnKey.PRICE_COMPARE_2],
        ].filter(Boolean).length;

        const datasets: ChartDataSets[] = this.entities.reduce((acc, entityId) => {
            // i === 0 - main room
            // i === 1 || i === 2 - compared rooms
            const entityDatasets = Array(roomsNumber).fill(null).map((_, index) => {
                let borderColor = `rgba(${hexToRgb(this.entityColors[entityId])}, 1)`;

                if (index !== 0) {
                    borderColor = `rgba(${hexToRgb(this.entityColors[entityId])}, 0.3)`;
                }

                const pricesTrend: (number | null)[] = this.trendsDocument!.trendData.map(trend => {
                    const room = trend?.data[entityId]?.rooms[index];

                    if (!room || !room.price) {
                        return null;
                    }

                    const price = room.price[`${this.priceShown}Price` as keyof RatesDocumentItemModel['price']];

                    // Sold out case (price === 0) should not be represented on the graph
                    return (price && price > 0) ? price : null;
                }).reverse();

                return {
                    data: pricesTrend,
                    pointBorderWidth: 1,
                    borderWidth: entityId === String(this.compset.ownerHotelId) ? 3 : 2,
                    borderColor,
                    lineTension: 0,
                    borderJoinStyle: 'round',
                    hidden: this.hiddenEntities[entityId],
                } as ChartDataSets;
            });

            return acc.concat(entityDatasets);
        }, [] as ChartDataSets[]);

        return {
            labels,
            datasets,
        };
    }

    setTooltipElement(el?: HTMLElement) {
        this.tooltipFocusElement = el || null;
    }

    setTooltipDay(label: string) {
        this.tooltipTrendIndex = (label
            ? Math.abs(parseInt(label, 10))
            : null);
    }

    handleTrendIndexChange(label: string) {
        const trendIndex = parseInt(label.replace('-', '').trim(), 10);
        this.$emit('trendIndexChange', trendIndex);
    }
}
