
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import PRICE from '@/modules/common/modules/rates/constants/price.enum';
import type HelperService from '@/modules/common/services/helper.service';
import { ASSESSMENT_TYPES } from '@/modules/common/constants';
import CompsetScale from '@/modules/common/components/compset-scale.vue';
import Occupancy from '@/modules/common/components/ui-kit/occupancy/index.vue';
import Demand from '@/modules/common/components/ui-kit/demand/index.vue';
import type { TrendsStatistics } from '../../types';

@Component({
    components: {
        CompsetScale,
        Demand,
        Occupancy,
    },
})
export default class Statistics extends Vue {
    @inject(KEY.HelperService) private helperService!: HelperService;

    @Prop({ type: Object, default: null })
    dayStatistics!: TrendsStatistics | null;

    get noDataMod() {
        if (!this.dayStatistics?.compset) {
            return true;
        }

        if (this.dayStatistics.compset.type === 'average') {
            return this.getNoDataMod(this.dayStatistics.compset.compRates[0]);
        }

        return this.getNoDataMod(this.dayStatistics.compset.mainRate as number | null, this.dayStatistics.compset.compRates[0]);
    }

    get color() {
        if (!this.dayStatistics?.compset) {
            return { grey: true };
        }

        if (this.noDataMod === 'na') {
            return { red: true };
        }

        return this.getColor(this.dayStatistics.compset.assessment);
    }

    formatPrice(price: number | null) {
        const noDataMod = this.getNoDataMod(price);

        if (noDataMod) {
            return this.$tc(noDataMod);
        }

        return `${this.helperService.currencySymbol(this.dayStatistics?.currency || null) || ''}${price}`;
    }

    private getNoDataMod(price1: number | null, price2?: number | null) {
        if (price1 === PRICE.SOLD_OUT) {
            return 'soldOut';
        }

        if (price1 === PRICE.NA || price1 === null && price2 !== null) {
            return 'na';
        }

        if (price1 === null) {
            return 'noData';
        }

        return null;
    }

    private getColor(assessment: ASSESSMENT_TYPES | null) {
        switch (assessment) {
            case ASSESSMENT_TYPES.GOOD:
                return { green: true };
            case ASSESSMENT_TYPES.NORMAL:
                return { yellow: true };
            case ASSESSMENT_TYPES.BAD:
                return { red: true };
            default:
                return { grey: true };
        }
    }
}
