
import { Component, Prop, Vue } from 'vue-property-decorator';
import _ from 'lodash';
import moment from 'moment';

import PopupEventsContainer from '@/modules/events/components/popup-events-container.vue';
import JsonViewer from '@/modules/common/components/ui-kit/json-viewer.vue';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import TogglePrices from '@/modules/document-filters/components/toggle-prices.vue';
import { PRICE_SHOWN } from '@/modules/rates/constants';
import CompsetModel from '@/modules/compsets/models/compset.model';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';

import Filters from './filters.vue';
import Table from './table.vue';
import Statistics from './statistics.vue';
import Chart from './chart.vue';
import Header from './header.vue';
import type {
    ScanProps,
    TableColumns,
    TableOptions,
    TrendFilters,
    TrendsDocument,
    TrendsStatistics,
} from '../../types';

@Component({
    components: {
        ModalWrapper,
        TogglePrices,
        JsonViewer,
        PopupEventsContainer,
        Filters,
        Chart,
        Table,
        Statistics,
        Header,
    },
})
export default class DayPopup extends Vue {
    /** Pure component. Only for internal use in other popups, don't use it directly. Don't inject services */

    @Prop({ type: Object })
    tableColumns!: TableColumns;

    @Prop({ type: Object, default: () => ({}) })
    tableOptions!: TableOptions;

    @Prop({ type: Object })
    tooltipColumns?: TableColumns;

    @Prop({ type: Object, default: () => ({}) })
    tooltipOptions!: TableOptions;

    @Prop({ type: Object, default: null })
    trendsDocument!: TrendsDocument | null;

    @Prop({ type: Object, default: null })
    filters!: TrendFilters | null;

    @Prop({ type: Array })
    statistics!: (TrendsStatistics | null)[];

    @Prop({ type: Date })
    date!: Date;

    @Prop({ type: String })
    priceShown!: PRICE_SHOWN;

    @Prop({ type: Object, default: null })
    compset!: CompsetModel | null;

    @Prop({ type: Array, default: () => ([]) })
    colors!: string[];

    @Prop({ type: Object, default: null })
    scanProps!: ScanProps | null;

    @Prop({ type: Boolean, default: false })
    isDocumentLoading!: boolean;

    @Prop({ type: Boolean, default: false })
    isChartLoading!: boolean;

    @Prop({ type: Boolean, default: false })
    isNoChart!: boolean;

    @Prop({ type: Boolean, default: false })
    isSuperadmin!: boolean;

    hiddenEntities: Record<string, boolean> = {};
    showRawData = false;

    tooltipFocusElement: HTMLElement | null = null;
    trendIndex: number = 0;

    get entityColors() {
        if (!this.compset || !this.filters?.entities) {
            return {};
        }

        const { entities } = this.filters;

        const colors = entities
            .filter(h => h !== String(this.compset!.ownerHotelId))
            .reduce((acc, id, index) => ({
                ...acc,
                [id]: this.colors[index],
            }), {} as Record<string, string>) || {};

        if (entities.includes(String(this.compset.ownerHotelId))) {
            colors[String(this.compset.ownerHotelId)] = CURRENT_HOTEL_GRAPH_COLOR;
        }

        return colors;
    }

    get formatedScanDate() {
        if (!this.scanProps?.lastScan) {
            return '---';
        }

        const scannedAtDate = new Date(this.scanProps.lastScan);
        scannedAtDate.setDate(scannedAtDate.getDate() - this.trendIndex);

        return moment(scannedAtDate).format('DD.MM');
    }

    handleToggleEntity(id: string) {
        if (this.hiddenEntities[id]) {
            delete this.hiddenEntities[id];
            this.hiddenEntities = {
                ...this.hiddenEntities,
            };
            return;
        }
        this.hiddenEntities = {
            ...this.hiddenEntities,
            [id]: true,
        };
    }

    handleTrendIndexChange(trendIndex: number) {
        this.trendIndex = trendIndex;
    }
}
