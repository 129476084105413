
import { Component, Vue, Prop } from 'vue-property-decorator';
import DayChanger from '@/modules/common/components/day-changer.vue';
import RatesScanBtn from '@/modules/common/modules/rates/components/rates-scan-btn.vue';

import type { ScanProps } from '../../types';

@Component({
    components: {
        DayChanger,
        RatesScanBtn,
    },
})
export default class RatesDemandMapHeader extends Vue {
    @Prop({ type: Object, default: null })
    scanProps!: ScanProps | null;

    @Prop({ type: Boolean, default: false })
    isDisabled!: boolean;
}
